<template>
    <div class="relative bg-white pt-16">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start pb-10">
            <div class="relative sm:py-16 lg:py-0">
                <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                    <div class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
                    <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404"
                         height="392" fill="none" viewBox="0 0 404 392">
                        <defs>
                            <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20"
                                     patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"/>
                    </svg>
                </div>
                <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                    <!-- Testimonial card-->
                    <div class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                        <img class="absolute inset-0 h-full w-full object-cover"
                             src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1934&q=80"
                             alt="">

                        <div class="absolute inset-0 bg-gradient-to-t from-indigo-500 via-indigo-600 opacity-90"></div>
                        <div class="relative px-8">

                            <blockquote class="mt-8">
                                <div class="relative text-lg font-medium text-white md:flex-grow">
                                    <svg
                                        class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-400"
                                        fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                                        <path
                                            d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"/>
                                    </svg>
                                    <p class="relative">
                                        With the tokengen affiliate program there is no limits on how much you can earn.
                                        Get started today and earn up to 25% commission on every sale!
                                    </p>
                                </div>

                                <footer class="mt-4">
                                    <p class="text-base font-semibold text-indigo-200">Tokengen Affiliate Program</p>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                <!-- Content area -->
                <div class="pt-12 sm:pt-16 lg:pt-20">
                    <h2 class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                        Tokengen Affiliate Program
                    </h2>
                    <div class="mt-6 text-gray-500 space-y-6">
                        <p class="text-lg">
                            The tokengen affiliate program is for everyone. Whether you're a content creator on youtube,
                            tiktok or just want to test out affiliate marketing, tokengen is for you.
                        </p>
                        <p class="text-base leading-7">
                            Tokengen opened up for public deployments May 2021. Since then we've deployed more than 1000
                            contracts on the Binance Smart Chain Network. Our community consists of more than 1000+
                            followers spreading accross our social media platforms.
                        </p>
                        <p class="text-base leading-7">
                            As an affiliate partner you'll get your own affiliate code, which your followers can use
                            upon checkout at tokengen. Your followers will gain a 10% discount upon deploying a contract
                            and you as an affiliate partner will also receive 10% of the total sale. Affiliate partners
                            can earn up to 25%, depending on how many contracts that has been deployed using your
                            affiliate code.
                        </p>
                    </div>
                </div>

                <!-- Stats section -->
                <div class="mt-10">
                    <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
                        <div class="border-t-2 border-gray-100 pt-6">
                            <dt class="text-base font-medium text-gray-500">Contracts deployed</dt>
                            <dd class="text-2xl font-extrabold tracking-tight text-gray-900">1000+</dd>
                        </div>

                        <div class="border-t-2 border-gray-100 pt-6">
                            <dt class="text-base font-medium text-gray-500">Earn up to</dt>
                            <dd class="text-2xl font-extrabold tracking-tight text-gray-900">25% commission</dd>
                        </div>

                        <div class="border-t-2 border-gray-100 pt-6">
                            <dt class="text-base font-medium text-gray-500">Access to</dt>
                            <dd class="text-2xl font-extrabold tracking-tight text-gray-900">Branding materials</dd>
                        </div>

                        <div class="border-t-2 border-gray-100 pt-6">
                            <dt class="text-base font-medium text-gray-500">Extra</dt>
                            <dd class="text-2xl font-extrabold tracking-tight text-gray-900">Live support assistance
                            </dd>
                        </div>
                    </dl>

                </div>
            </div>
        </div>
        <div class="bg-gray-50">

            <section aria-labelledby="comparisonHeading" class="hidden lg:block">

                <div class="max-w-7xl mx-auto py-24 px-8">
                    <div class="w-full border-t border-gray-200 flex items-stretch">
                        <div class="-mt-px w-1/5 py-6 pr-4 flex items-end">
                            <h3 class="mt-auto text-sm font-bold text-gray-900">Affiliate Partner Program</h3>
                        </div>

                        <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/5">
                            <div class="border-transparent py-6 border-t-2">
                                <p class="text-gray-900 text-sm font-bold text-gray-900">Bronze</p>
                                <p class="mt-2 text-sm text-gray-500">Bronze partner is the starting partner
                                    program.</p>
                            </div>
                        </div>

                        <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/5">
                            <div class="border-transparent py-6 border-t-2">
                                <p class="text-gray-600 text-sm font-bold text-gray-900">Silver</p>
                                <p class="mt-2 text-sm text-gray-500">Silver partner is achieved after deploying 10
                                    contracts with your affiliate code.</p>
                            </div>
                        </div>

                        <div aria-hidden="true" class="-mt-px pl-4 w-1/5">
                            <div class="border-transparent py-6 border-t-2">
                                <p class="text-gray-900 text-sm font-bold text-gray-900">Gold</p>
                                <p class="mt-2 text-sm text-gray-500">Gold partner is achieved after deploying 20
                                    contracts with your affiliate code.</p>
                            </div>
                        </div>
                        <div aria-hidden="true" class="-mt-px pl-4 w-1/5">
                            <div class="border-transparent py-6 border-t-2">
                                <p class="text-gray-900 text-sm font-bold text-gray-900">Diamond</p>
                                <p class="mt-2 text-sm text-gray-500">Diamond partner is achieved after deploying 30
                                    contracts with your affiliate code.</p>
                            </div>
                        </div>
                    </div>

                    <div class="relative">
                        <!-- Fake card backgrounds -->
                        <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div class="w-1/5 pr-4"></div>
                            <div class="w-1/5 px-4">
                                <div class="w-full h-full bg-white rounded-lg shadow"></div>
                            </div>
                            <div class="w-1/5 px-4">
                                <div class="w-full h-full bg-white rounded-lg shadow"></div>
                            </div>
                            <div class="w-1/5 pl-4">
                                <div class="w-full h-full bg-white rounded-lg shadow"></div>
                            </div>
                            <div class="w-1/5 pl-4">
                                <div class="w-full h-full bg-white rounded-lg shadow"></div>
                            </div>
                        </div>

                        <table class="relative w-full">
                            <tbody class="divide-y divide-gray-100">
                            <tr>
                                <th scope="row" class="w-1/5 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                    Commission
                                </th>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <span class="text-gray-900 text-sm font-medium">10%</span>
                </span>
                                </td>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <span class="text-gray-900 text-sm font-medium">15%</span>
                </span>
                                </td>

                                <td class="pl-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <span class="text-gray-900 text-sm font-medium">20%</span>
                </span>
                                </td>

                                <td class="pl-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <span class="text-gray-900 text-sm font-medium">25%</span>
                </span>
                                </td>
                            </tr>

                            <tr>
                                <th scope="row" class="w-1/5 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                    Discount
                                </th>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <span class="text-gray-900 text-sm font-medium">10%</span>
                </span>
                                </td>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <span class="text-gray-900 text-sm font-medium">10%</span>
                </span>
                                </td>

                                <td class="pl-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <span class="text-gray-900 text-sm font-medium">12%</span>
                </span>
                                </td>
                                <td class="pl-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <span class="text-gray-900 text-sm font-medium">15%</span>
                </span>
                                </td>
                            </tr>

                            <tr>
                                <th scope="row" class="w-1/5 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                    Instant payout
                                </th>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                                    <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </td>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                                    <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </td>

                                <td class="pl-4 relative w-1/5 py-0 text-center">
                                    <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </td>
                                <td class="pl-4 relative w-1/5 py-0 text-center">
                                    <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </td>
                            </tr>

                            <tr>
                                <th scope="row" class="w-1/5 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                    Affiliate Partner Telegram
                                </th>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                                    <!-- Heroicon name: solid/x -->
                                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </td>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                                    <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </td>

                                <td class="pl-4 relative w-1/5 py-0 text-center">
                                    <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </td>

                                <td class="pl-4 relative w-1/5 py-0 text-center">
                                    <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </td>
                            </tr>


                            <tr>
                                <th scope="row" class="w-1/5 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                    Extended support
                                </th>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <!-- Heroicon name: solid/x -->
                  <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
                                </td>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <!-- Heroicon name: solid/check -->
                  <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
                                </td>

                                <td class="pl-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <!-- Heroicon name: solid/check -->
                  <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
                                </td>
                                <td class="pl-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <!-- Heroicon name: solid/check -->
                  <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="w-1/5 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                    Impact in future development
                                </th>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <!-- Heroicon name: solid/x -->
                  <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
                                </td>

                                <td class="px-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <!-- Heroicon name: solid/x -->
                  <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
                                </td>

                                <td class="pl-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <!-- Heroicon name: solid/x -->
                  <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
                                </td>
                                <td class="pl-4 relative w-1/5 py-0 text-center">
                <span class="relative w-full h-full py-3">
                  <!-- Heroicon name: solid/x -->
                  <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
                                </td>
                            </tr>


                            </tbody>
                        </table>

                        <!-- Fake card borders -->
                        <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div class="w-1/5 pr-4"></div>
                            <div class="w-1/5 px-4">
                                <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                            </div>
                            <div class="w-1/5 px-4">
                                <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5 "></div>
                            </div>
                            <div class="w-1/5 pl-4">
                                <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row-reverse mt-2">
                        <div class="italic text-xs text-gray-500">*Free contracts does not count towards the affiliate
                            program.
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="bg-gray-100">
            <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div class="relative bg-white shadow-xl">

                    <div class="grid grid-cols-1 lg:grid-cols-3">
                        <!-- Contact information -->
                        <div class="relative overflow-hidden py-10 px-6 bg-indigo-700 sm:px-10 xl:p-12">
                            <div class="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                                <svg class="absolute inset-0 w-full h-full" width="343" height="388"
                                     viewBox="0 0 343 388" fill="none" preserveAspectRatio="xMidYMid slice"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                          fill="url(#linear1)" fill-opacity=".1"/>
                                    <defs>
                                        <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66"
                                                        gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#fff"></stop>
                                            <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                                aria-hidden="true">
                                <svg class="absolute inset-0 w-full h-full" width="359" height="339"
                                     viewBox="0 0 359 339" fill="none" preserveAspectRatio="xMidYMid slice"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                          fill="url(#linear2)" fill-opacity=".1"/>
                                    <defs>
                                        <linearGradient id="linear2" x1="192.553" y1="28.553" x2="899.66" y2="735.66"
                                                        gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#fff"></stop>
                                            <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                                 aria-hidden="true">
                                <svg class="absolute inset-0 w-full h-full" width="160" height="678"
                                     viewBox="0 0 160 678" fill="none" preserveAspectRatio="xMidYMid slice"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                          fill="url(#linear3)" fill-opacity=".1"/>
                                    <defs>
                                        <linearGradient id="linear3" x1="192.553" y1="325.553" x2="899.66" y2="1032.66"
                                                        gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#fff"></stop>
                                            <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <h3 class="text-lg font-medium text-white">Interested in becoming a partner?</h3>
                            <p class="mt-6 text-base text-indigo-50 max-w-3xl">Fill out this form to apply for our
                                affiliate program.</p>

                        </div>

                        <!-- Contact form -->
                        <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12 mh-12">
                            <iframe
                                src="https://docs.google.com/forms/d/e/1FAIpQLScxl3hSiGOg7ct9bzHqQSgwuWJFcx22JS2crIHgYoTHHf1AtA/viewform?embedded=true"
                                width="640" height="1186" frameborder="0" marginheight="0" marginwidth="0">Loading…
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Affiliate.vue"
}
</script>

<style scoped>

</style>
